import { _t } from "@/tools/Utils";

export const CONTRACT_HEADER = {
  headers: [
    {
      text: _t("attribute.contrcatType"),
      sortable: false,
      value: "type",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.id"),
      sortable: false,
      value: "refID",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.studentName"),
      sortable: false,
      value: "studentName",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.additionalContracts"),
      sortable: false,
      value: "detail",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.contractFile"),
      sortable: false,
      value: "file",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.contractValue"),
      sortable: false,
      value: "contractValue",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.discountValue"),
      sortable: false,
      value: "discountValue",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.total"),
      sortable: false,
      value: "total",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.createMoment"),
      sortable: false,
      value: "createMoment",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.balance"),
      sortable: false,
      value: "edit",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: "",
      sortable: false,
      value: "actions",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
};
