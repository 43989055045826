<template>
  <v-row>
    <v-col cols="12">
      <ContractTable />
    </v-col>
  </v-row>
</template>

<script>
import ContractTable from "@/packages/admin/components/contract/ContractTable";
export default {
  name: "contract",
  components: { ContractTable },
};
</script>

<style scoped></style>
