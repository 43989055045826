<template>
  <div>
    <v-row>
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :dark="dark" :title="$_t('attribute.contract')" />
      </v-col>
      <v-spacer />
      <v-col cols="12" md="2">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              width="11rem"
              height="2.5rem"
              color="primary"
              dark
              class="float-end"
              v-bind="attrs"
              v-on="on"
              >{{ $_t("attribute.export") }}</v-btn
            >
          </template>
          <v-date-picker
            type="month"
            color="primary"
            v-model="date"
            @input="exportContracts"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <AddContract
          @update="init({})"
          @clear="clear"
          ref="addContract"
          :contract="contract"
        >
          <template v-slot:activator>
            <v-btn
              width="11rem"
              height="2.5rem"
              color="primary"
              dark
              class="float-end"
            >
              <v-icon class="mr-3">mdi-file-sign</v-icon>
              {{ $_t("attribute.addContract") }}
            </v-btn>
          </template>
        </AddContract>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            init({ search: $event });
          "
          @detail="editContract"
          @delete="deleteContract"
          :headers="headers"
          :items="contracts"
          :page-info="pageInfo"
          :loading="is_loading"
        >
          <template v-slot:actions></template>
          <template v-slot:detail="{ item }">
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-if="item.additionalContracts.length"
                >
                  {{ $_t("attribute.seeAdditionalContracts") }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title
                  v-for="(contract, index) in item.additionalContracts"
                  :key="index"
                  class="text-h5 grey lighten-2 justify-center"
                >
                  {{ `${contract.title}: ${contract.value}` }}
                </v-card-title>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:type="{ item }">
            {{ $_t(`attribute.${item.contrcatType}`) }}
          </template>
          <template v-slot:edit="{ item }">
            <v-btn
              color="primary"
              @click="seeContractBalance(item.studentPublicKey)"
            >
              {{ $_t("attribute.contractBalance") }}
            </v-btn>
          </template>
          <template #file="{ item }">
            <v-btn
              v-if="item.contractFileName"
              @click="
                fileDownloadUrlDynamic(item.contractFileName, 'getContractFile')
              "
              color="primary"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </template>
          <template v-slot:createMoment="{ item }">
            {{ $_date(item.createMoment, "long") }}
          </template>
          <template v-slot:pagination>
            <v-pagination
              :dark="dark"
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="init({})"
            ></v-pagination>
          </template>
        </MainTable>
      </v-col>
      <Contracts
        @close="contractModal = false"
        :contracts="contractBalance"
        :is-show-modal="contractModal"
      />
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { reactive, ref } from "vue";
import { CONTRACT_HEADER } from "@/packages/admin/schema/contract/CONTRACT_TABLE_HEADER.schema";
import { mapGetters } from "vuex";
import AddContract from "@/packages/admin/components/contract/AddContract";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import FileDownload from "js-file-download";
import Contracts from "@/packages/finance/components/deposit/Contracts";
import moment from "moment";
import { fileDownloadUrlDynamic } from "@/tools/FileManager";

export default {
  name: "ContractTable",
  components: { Contracts, AddContract, MainTable, TopTitle },
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contract: null,
      contractBalance: [],
      contractModal: false,
      date: null,
      menu: false,
      fileDownloadUrlDynamic,
    };
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
      user: "authentication/user",
      contracts: "contract/contracts",
    }),
  },
  setup(props) {
    const lessonType = ref(null);
    const isShowLessonModal = ref(false);
    const baseType = ref(null);
    const headers = CONTRACT_HEADER.headers;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: props.itemsPerPage || 10,
    });
    return {
      lessonType,
      baseType,
      headers,
      pageInfo,
      isShowLessonModal,
    };
  },
  methods: {
    async init({ search = this.search }) {
      this.search = search;
      const { pageCount } = await this.$actions.getContracts({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        search: this.search,
        role: this.authRole,
      });
      this.pageInfo.pageCount = pageCount;
    },
    async editContract(item) {
      this.contract = item;
      this.$refs.addContract.show();
    },
    async deleteContract(item) {
      await this.$actions.deleteContract({ contractId: item.contractId });
      await this.init({});
    },
    async seeContractBalance(publicKey) {
      this.contractBalance = await this.$actions.getStudentsContractBalance({
        studentPublicKey: publicKey,
      });
      this.contractModal = true;
    },
    async clear() {
      this.contract = null;
    },
    async exportContracts() {
      await api
        .get(
          urls.qs("exportContracts", {
            filter: this.search,
            year: moment(this.date).year(),
            month: moment(this.date).month() + 1,
          }),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileDownload(res, `${this.date}-ContractsReports.xlsx`);
        });
    },
  },
  mounted() {
    this.init({});
  },
};
</script>

<style scoped></style>
