import { _t } from "@/tools/Utils";

const { required, numeric } = require("vuelidate/lib/validators");

export const ADD_CONTRACT = {
  /* Form SCHEMA */
  schema: [
    {
      id: "students",
      label: "students",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "select",
      text: (item) => `${item.fullName} | ${item.nickName}`,
      value: "publicKey",
      show: true,
      searchable: true,
      items: [],
    },
    {
      id: "contrcatType",
      label: "contrcatType",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "select",
      text: "text",
      value: "value",
      show: true,
      items: [
        {
          text: _t("attribute.ApplicationForIndependentSchools"),
          value: "ApplicationForIndependentSchools",
        },
        {
          text: _t("attribute.ApplicationForUniversities"),
          value: "ApplicationForUniversities",
        },
        {
          text: _t("attribute.ProjectsOrAcademicConferences"),
          value: "ProjectsOrAcademicConferences",
        },
      ],
    },
    {
      id: "contractValue",
      label: "contractValue",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "discountValue",
      label: "discountValue",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "contractFile",
      label: "contractFile",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "file",
    },
  ],

  /* Form MODEL */
  model: {
    students: [],
    contrcatType: null,
    contractValue: null,
    discountValue: 0,
    contractFile: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      students: {
        required,
      },
      contrcatType: {
        required,
      },
      contractValue: { required, numeric },
      discountValue: { numeric },
      contractFile: {},
    },
  },
};
