<template>
  <v-dialog persistent v-model="menu" width="500px">
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>
    <template v-slot:default>
      <v-card class="px-4">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">{{
            contract
              ? $_t("attribute.editContract")
              : $_t("attribute.addContract")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-4"> </v-card-text>
        <MainSoftForm>
          <template v-slot:body>
            <MyFormGenerator
              lg="10"
              :schema="schema"
              @searchInItems="searchStudents"
              :validator="$v"
            ></MyFormGenerator>
            <v-progress-linear
              v-if="progress > 0"
              v-model="progress"
              color="primary"
            ></v-progress-linear>
            <v-container>
              <v-btn class="mb-4" color="primary" @click="addContract"
                >{{ $_t("attribute.addAdditionalContract")
                }}<v-icon>mdi-plus</v-icon></v-btn
              >
              <v-row v-if="additionalContracts.length">
                <v-col
                  cols="12"
                  v-for="contract in additionalContracts"
                  :key="contract.id"
                >
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        :label="$_t('attribute.additionalContractTitle')"
                        v-model="contract.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        outlined
                        dense
                        type="number"
                        :label="$_t('attribute.additionalContractValue')"
                        hide-details
                        v-model="contract.value"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="primary"
                        outlined
                        small
                        fab
                        @click="removeContract(contract.id)"
                        ><v-icon>mdi-minus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ $_t("attribute.cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  class="ml-4"
                  @click="submit"
                  :loading="is_loading"
                  :disabled="is_loading || $v.$error"
                >
                  {{
                    contract
                      ? $_t("attribute.editContract")
                      : $_t("attribute.addContract")
                  }}
                </v-btn>
              </div>
            </v-col>
          </template>
        </MainSoftForm>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { ADD_CONTRACT } from "@/packages/admin/schema/contract/ADD_CONTRACT.schema";
import message from "@/tools/Message";
import api from "@/tools/Api";
import urls from "@/tools/Urls";

export default {
  name: "AddContract",
  components: { MyFormGenerator, MainSoftForm },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  props: {
    contract: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      menu: false,
      schema: ADD_CONTRACT.schema,
      form: ADD_CONTRACT.model,
      additionalContracts: [],
      progress: 0,
    };
  },
  validations: {
    ...ADD_CONTRACT.validations,
  },
  watch: {
    async menu(value) {
      if (value) {
        await this.searchStudents({ search: null, id: "students" });
        if (this.contract) {
          const {
            contractValue,
            contrcatType,
            studentPublicKey,
            additionalContracts,
            discountValue,
          } = this.contract;
          this.form.contractValue = contractValue;
          this.form.contrcatType = contrcatType;
          this.form.students = studentPublicKey;
          this.form.discountValue = discountValue ? discountValue : 0;

          this.additionalContracts = [...additionalContracts];
        }
      }
    },
  },
  methods: {
    async submit() {
      if (!this.isFormValidate()) return;
      let imgId = null;
      if (this.form.contractFile) {
        const formData = new FormData();
        formData.append("contractFile", this.form.contractFile);
        const { data } = await api.post(
          urls.qs("uploadContractFile", { name: this.form.contractFile.name }),
          formData,
          {
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        );
        imgId = data;
      }
      if (this.additionalContracts.length) {
        const empty = this.additionalContracts.find(
          (c) =>
            c.title === null ||
            c.title === "" ||
            c.value === null ||
            c.value === 0
        );
        if (empty) return message.error(this.$_t("attribute.checkFields"));
      }
      let res = null;
      if (this.contract) {
        res = await this.$actions.editContract({
          contractId: this.contract.contractId,
          contrcatType: this.form.contrcatType,
          contractValue: parseFloat(this.form.contractValue),
          contractFileName: imgId,
          discountValue: this.form.discountValue
            ? parseFloat(this.form.discountValue)
            : 0,
          additionalContracts: this.additionalContracts.map((c) => ({
            ...c,
            value: parseFloat(c.value),
          })),
        });
      } else {
        res = await this.$actions.addContract({
          studentPublicKey: this.form.students,
          contrcatType: this.form.contrcatType,
          contractFileName: imgId,
          contractValue: parseFloat(this.form.contractValue),
          discountValue: this.form.discountValue
            ? parseFloat(this.form.discountValue)
            : 0,
          additionalContracts: this.additionalContracts.map((c) => ({
            ...c,
            value: parseFloat(c.value),
          })),
        });
      }
      this.progress = 0;
      if (res) {
        this.$emit("update");
        this.close();
      }
    },
    async searchStudents({ search, id }) {
      this.schema[this.getIdWithName(this.schema, id)].items = (
        await this.$actions.getUsers({ all: true, role: "Student", search })
      ).users;
    },
    addContract() {
      this.additionalContracts.push({
        title: null,
        value: null,
        id: this.generateId(),
      });
    },
    removeContract(id) {
      this.additionalContracts = this.additionalContracts.filter(
        (c) => c.id !== id
      );
    },
    close() {
      this.menu = false;
      this.$emit("clear");
      this.clear();
    },
    clear() {
      this.form.contractFile = null;
      this.form.discountValue = 0;
      this.form.students = null;
      this.form.contrcatType = null;
      this.form.contractValue = null;
      this.$v.$reset();
    },
    show() {
      this.menu = true;
    },
  },
};
</script>

<style scoped></style>
